<template>
  <div class="row h-100 justify-content-between">
    <div class="d-none d-lg-block col-lg-2 col-xl-2 bg-white">
      <div class="pl-4">
        <Phaseline
            :phases="process.processPhases"
            :processId="process.id"
            :processLogo="getProcessLogo">
        </Phaseline>
      </div>
    </div>
    <div class="col-lg-9 col-xl-9 justify-content-start">
      <div class="d-flex flex-column px-6 mt-5">
        <!--::Process Info -->
        <div class="card mb-3 card-stretch shadow-sm">
          <div class="card-header border-0 pb-4">
            <div class="row">
              <div class="col-12">
                <h1
                  class="text-dark text-hover-primary font-weight-bolder mb-1"
                >
                  {{$t("PROCESSLISTCHALLENGES.OBIETTIVI")}}
                </h1>
                <p>
                  {{$t("PROCESSLISTCHALLENGES.ARGOMENTI")}}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--::Tool mobile-->
        <div class="row my-2">
          <ProcessMenuMobile :process_id="id"></ProcessMenuMobile>
        </div>
      </div>

      <div class="d-flex flex-column px-6 w-100">
        <div v-if="process.process2Challenges.length > 0" class="row">
          <div
            class="col-12 col-lg-6 col-xl-4"
            v-for="(item, index) in process.process2Challenges"
            :key="item.id"
          >
            <ChallengeCard :challenge="item" :index="index"></ChallengeCard>
          </div>
        </div>
        <div class="row mx-6" v-else>
            <p class="lead"> {{$t("PROCESSLISTCHALLENGES.NOTEMATICHE")}}</p>
        </div>
      </div>
    </div>
    <div
      class="d-none d-lg-block col-lg-1 col-xl-1 justify-content-between  align-items-center"
    >
      <div class="d-flex flex-column mt-5 mr-5">
        <ProcessMenu :process_id="process_id"></ProcessMenu>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ChallengeCard from "@/view/pages/process/partials/ChallengeCard";
import Phaseline from "@/view/layout/common/Phaseline";
import ProcessMenu from "@/view/layout/common/ProcessMenu";
import ProcessMenuMobile from "@/view/layout/common/ProcessMenuMobile";

import { processMixin } from "@/mixins/process";

export default {
  name: "ProcessListChallenges",
  mixins: [processMixin],
  components: {
    Phaseline,
    ChallengeCard,
    ProcessMenu,
    ProcessMenuMobile
  },
  computed: {
    isBandoZac() {
      return window.location.host.includes('bandozac.bipart.it');
    },
  },
  data() {
    return {
      process: [],
      process_id: this.$route.params.process_id,
      tot_challenges: 0,
      percentage: 0
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      //{ title: "Elenco Processi", route: "/process-list" },
      { title: this.$t("BCPROCESSI.PROCESSO"), route: "/process-detail/" + this.process_id },
      { title: this.isBandoZac ? "Format di innovazione" : this.$t("BCPROCESSI.CHALLENGE") }
    ]);
  },
  created() {
    this.getProcess(this.process_id).then(res => {
      this.process = res.data;
      this.percentage = this.getPercentage(this.process);
      this.tot_challenges = this.process.process2Challenges.length;
    });
  }
};
</script>

<style scoped></style>
